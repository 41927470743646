<template>
  <div class="font-Poppins bg-white">
    <div class="relative flex flex-col">
      <Navbar :pageSelected="'home'"/>
      <div class="absolute inset-0 w-full backdrop-blur-sm">
        <img :src="immondizia" alt="immondizia" class="object-cover h-full w-full">
      </div> 
      <div class="z-20 w-full text-left container">
        <h1 class="text-white text-4xl md:text-6xl [text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] mt-20 z-20 font-bold w-full md:w-3/4">Trasforma le Scadenze in OPPORTUNITÀ: Riduci gli Sprechi, Aumenta i Ricavi.</h1>
        <p class="text-white text-xl [text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] mb-24 z-20 w-full md:w-3/4 my-2">ReFoody è la piattaforma marketplace innovativa che consente ai supermercati di aumentare la visibilità delle referenze in scadenza al fine di monetizzarli e aumentare la sostenibilità del punto vendita. Con ReFoody, puoi raggiungere nuovi clienti e massimizzare i ricavi, mentre contribuisci a un futuro più sostenibile.</p>
      </div>
    </div>
    <home-component></home-component>
    <div class="bg-site-secondary">
      <Footer/>
    </div>
  </div>
</template>

<script>
import immondizia from '@/assets/backgrounds/sfondohome.jpg';
import Navbar from "@/components/navbar-component.vue";
import Footer from "@/components/footer-component.vue";
import HomeComponent from "@/components/home-component.vue";

export default {
  name: "HomeView",
  components: {
    Navbar,
    Footer,
    HomeComponent
  },
  mounted() {
    window.addEventListener('resize', () => this.w = window.innerWidth )
  },
  data() {
    return {
      w: window.innerWidth,
      minWidth: 768,
      immondizia,
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 1440px;
}
</style>