<template>
    <div class="flex flex-col h-screen justify-between bg-slate">
      <Navbar :pageSelected="'0'"></Navbar>
      <div class="m-auto  text-white text-center bg-site-tertiary border border-site-tertiary bg-opacity-40 p-32 px-64 rounded-lg shadow-xl">
        <p class="font-bold text-2xl">Error 404</p>
        <p class="">Page not fount</p>
      </div>
      <Footer></Footer>
    </div>
  </template>
    
  <script>
  import Footer from '@/components/footer-component.vue';
  import Navbar from '@/components/navbar-component.vue';
  
  export default{
    name: 'notFoundView',
    components: {
      Footer,
      Navbar,
    },
  };
  </script>
    