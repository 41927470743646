<template>
  <footer class="z-20 text-white py-10 font-Poppins backdrop-blur-sm">
    <div class="container mx-auto w-full grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
      <div class="flex flex-col items-center md:items-start">
        <h3 class="text-xl text-site-refoody-blue mb-4 font-bold [text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)]">SCARICA REFOODY</h3>
        <div class="flex gap-1">
          <p class="[text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] mb-2 font-bold">COMING SOON</p>
        </div>
      </div>
      <div class="flex flex-col items-center md:items-start">
        <h3 class="text-xl text-site-refoody-blue mb-4 font-bold [text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)]">CONTATTI</h3>
        <div class="flex gap-1">
          <p class="[text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] mb-2">MAIL</p>
          <p class="[text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] font-thin">admin@refoody.it</p>
        </div>
        <div class="flex gap-1">
          <p class="[text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] mb-2">TEL</p>
          <p class="[text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] font-thin">+39 339 622 4538</p>
        </div>
      </div>
      <div class="flex flex-col items-center md:items-start">
        <h3 class="text-xl text-site-refoody-blue mb-4 font-bold [text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)]">RESTIAMO IN CONTATTO</h3>
        <p class="[text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] mb-4">Lasciami la tua mail!</p>

        <div class="flex justify-center md:justify-start w-full">
          <input
            v-if="!showRecaptcha"
            type="email"
            placeholder="Email"
            class="flex-1 p-2 rounded-l-md rounded-r-none text-gray-800"
            v-model="emailsaved"
          />
          <button
            v-if="!showRecaptcha"
            @click="showRecaptcha = true"
            class="bg-site-refoody-yellow text-gray-800 p-2 rounded-r-md hover:bg-opacity-90"
          >
            INVIO
          </button>
        </div>

        <div class="flex justify-center md:justify-start w-full">
          <vue-recaptcha
            v-if="showRecaptcha"
            ref="vueRecaptcha"
            sitekey="6LekeVQqAAAAAKr2S4cK6pvBi9zLOZGJg8GXZ9KC"
            size="normal"
            theme="light"
            hl="it"
            @verify="recaptchaVerified"
            @expire="recaptchaExpired"
            @error="recaptchaError"
          ></vue-recaptcha>
        </div>
        <div v-if="erroreForm" class="bg-site-error bg-opacity-60 border border-site-error text-xs p-3 rounded-xl m-2">
          <p>{{ erroreForm }}</p>
        </div>
        <div v-if="successoForm" class="bg-site-refoody-blue bg-opacity-60 border border-site-refoody-blue text-xs p-3 rounded-xl m-2">
          <p>{{ successoForm }}</p>
        </div>
      </div>
    </div>

    <div class="text-center mt-8">
      <img src="../assets/instagram.png" alt="Instagram logo" class="size-5 bg-white rounded-full p-0.5 m-auto" />
      <a href="https://www.instagram.com/refoody_italia"
        class="text-white text-xs font-thin hover:text-site-refoody-yellow [text-shadow:_7px_7px_14px_rgb(0_0_0_/_60%)]">
        @Refoody_italia
      </a>
    </div>
  </footer>
</template>
<script>
import vueRecaptcha from 'vue3-recaptcha2';
import axios from 'axios';

export default {
  components: { vueRecaptcha },
  data() {
    return {
      emailsaved: '',         // Salva l'email inserita dall'utente
      erroreForm: '',         // Per mostrare eventuali errori
      successoForm: '',       // Per mostrare messaggi di successo
      showRecaptcha: false,   // Controlla se mostrare il CAPTCHA
      recaptchaToken: '',     // Token CAPTCHA
      loadingTimeout: 30000   // Timeout del CAPTCHA
    };
  },
  methods: {
    async recaptchaVerified(token) {
      this.recaptchaToken = token;

      try {
        const response = await axios.post('https://apis.refoody.it/mlist/insert', {
          email: this.emailsaved,
          captcha: this.recaptchaToken
        });
        if (response.status === 200) {
          this.successoForm = "Mail inserita con successo";
          this.resetForm();
        } else {
          this.erroreForm = "Errore: " + response.data.message;
        }
        this.showRecaptcha = true;
      } catch (error) {
        this.erroreForm = "Errore del server. Riprova più tardi.";
        this.showRecaptcha = true;
      }
    },

    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.erroreForm = "Il reCAPTCHA è scaduto. Per favore, riprova.";
    },

    recaptchaError(reason) {
      console.log(reason);
      this.erroreForm = "Errore nel CAPTCHA. Per favore, riprova.";
    },

    resetForm() {
      this.emailsaved = '';
      this.recaptchaToken = '';
      this.showRecaptcha = false;
      this.$refs.vueRecaptcha.reset();
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 1440px;
}
</style>